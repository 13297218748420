<app-page-heading>Start a new task</app-page-heading>

<ng-container *ngFor="let task of availableTasks$ | async">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <h2 class="govuk-heading-m">{{ task.title }}</h2>
      <ng-container *ngFor="let property of task.properties">
        <ng-template [ngIf]="!property.errors.length" [ngIfElse]="errors">
          <div>
            <button (click)="onRequestButtonClick(property.type)" appPendingButton govukButton type="button">
              {{ property.button }}
            </button>
          </div>
        </ng-template>
        <ng-template #errors>
          <ul class="govuk-list">
            <li *ngFor="let error of property.errors">
              {{ error }}
            </li>
          </ul>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <hr class="govuk-section-break govuk-section-break--m" />
</ng-container>
